/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.hamburger-menu{
color: #9fff40 !important;
}

.css-1lr2o5n {

  padding: 20px;
  border-radius: 30px;
  align-items: center !important;
  background-color: #0b1820cf !important;
  border: 1px solid #61ab37 !important;
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.3),  /* Strong shadow for depth */
              0 4px 6px rgba(255, 255, 255, 0.15),  /* Medium shadow for a softer effect */
              0 2px 4px rgba(255, 255, 255, 0.1);   /* Subtle shadow for a clean edge */
 
  position: sticky !important;
  top: 60px;
  left: 10px;
  right: 10px;
  z-index: 1000;
}


/* Body */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f1f1f1;
  color: #333;
  overflow-x: hidden; /* Prevent horizontal scroll */
}
.form-control {
  font-size: 14px;
  padding: 8px;
  width: 60%;
  text-align: center;
  border-radius: 30px;
  background-color: #262626;
  border: 1px solid #61ab37;
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.3),  /* Strong shadow for depth */
              0 4px 6px rgba(255, 255, 255, 0.15),  /* Medium shadow for a softer effect */
              0 2px 4px rgba(255, 255, 255, 0.1);   /* Subtle shadow for a clean edge */
}



.css-jenl9d {
  
  background: #9fff40 !important;
  color: #000000 !important;
}

.css-70px9w {
  background: #9fff40 !important;
  color: #000000 !important;
}



.css-5je9s7{
  background: #9fff40 !important;
  color: #000000 !important;
  

}

.css-h361w9 {
  background: #9fff40 !important;
  color: #000000 !important;
  
}

.css-b8twoo{
  color:#eff1a4 !important;
}



.css-1qevdtj {
  color:#eff1a4 !important;
}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgb(0 0 0 / 84%) !important;
}
.colored-toast.swal2-icon-success {
  background-color: #296f00 !important;
}

.colored-toast.swal2-icon-error {
  background-color: #6c0000 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #783800 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #005574 !important;
}

.colored-toast.swal2-icon-question {
  background-color: #00726a !important;
}

.colored-toast .swal2-title {
  color: rgb(101, 101, 101);
}

.swal2-success-circular-line-left{
  background-color: transparent !important;
}

.swal2-success-circular-line-right{
  background-color: transparent !important;
}
.swal2-icon.swal2-success .swal2-success-fix{
  background-color: transparent !important;
}

.colored-toast .swal2-close {
  color: rgb(47, 47, 47);
}

.colored-toast .swal2-html-container {
  color: rgb(27, 27, 27);
}

.swal2-popup {
  background: #141414 !important;
  color: #9c9c9c !important;
  border-radius: 60px !important;
  box-shadow: 0 4px 8px rgb(142 253 90), 
              0 6px 20px rgb(25 95 68), 
              0 8px 20px rgb(7 21 32) !important;}


.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 30px !important;
  background: initial;
  background-color: #dbdbdb !important;
  color: #000000 !important;
  font-size: 1em;
}


.css-jv498s {
  padding: 10px;
  background: #0b1820cf !important;
  box-shadow: 0 4px 8px rgb(142 253 90), 0 6px 20px rgb(25 95 68), 0 8px 20px rgb(7 21 32) !important;
  text-align: center !important;
  margin: 5px !important;
}


.css-whzx04{
  width: 350px;
  height:auto !important;
  border-radius: 0px !important;
}

.css-iqfqal  {
  background: #0b1820cf !important;
  border-radius: 30px;
  border: 1px solid #cfdbd5 !important;
  padding: 20px;
  box-shadow: 0 4px 8px rgb(142 253 90), 
              0 6px 20px rgb(25 95 68), 
              0 8px 20px rgb(7 21 32) !important;
  width: 100%;
  max-width: 800px !important;
  margin: auto;
  overflow: hidden;
  position: sticky;
  top: 0; /* Adjust to set the distance from the top of the viewport */
  z-index: 1000;
}
.css-iqfqal {
  border-radius: 30px !important;
}

.infoBox {
  text-align: center; /* Centers the text */
  padding: 20px;      /* Adds space inside the box */
  margin: 10px 0;     /* Adds space around the box */
}

.css-qroo13{
  box-shadow: 0 4px 8px rgb(142 253 90), 0 6px 20px rgb(25 95 68), 0 8px 20px rgb(7 21 32) !important;
  background: #a0ff4000 !important;
  color: #eff1a4  !important;
  width: 100%;
  border-radius: 30px !important;
}
/* Main Container */
.NFTHead {
  background: #0b1820cf !important;
  border-radius: 30px;
  border: 1px solid #cfdbd5 !important;
  padding: 20px;
  box-shadow: 0 4px 8px rgb(142 253 90), 0 6px 20px rgb(25 95 68), 0 8px 20px rgb(7 21 32) !important;
  width: 100%;
  max-width: 800px !important;
  margin: auto;
  overflow: hidden;
}

.popuptxt{
  font-size: 18px;
}
.popuptxtinfohead{
  font-size: 18px;
  text-align: center !important;
  margin-top: 10px;
  color: #9fff40 !important;
}
.popuptxtinfo{
  font-size: 14px;
  text-align: center !important;
  margin-bottom: 10px;
}
.card-header10{
  border-radius: 30px !important;
}

.card-header1{
  border: 1px solid #9fff40 !important;
  border-radius: 30px;
  font-size: 15px !important;
  margin: 5px !important;
}

.css-1gx57aq{
  background: transparent !important ;
}


.small{
  font-size: 8px !important;
}



.baseHead{
  font-size: 18px;
}
.cardtxt {
  font-size: 14px;
}

/* Content Box */
.css-hj8hg5 {
 
  box-shadow: 0 4px 8px rgb(142 253 90), 0 6px 20px rgb(25 95 68), 0 8px 20px rgb(7 21 32) !important;
  overflow: hidden;
  background-image: url(../Images/bg.png);
  background-size: cover; /* Ensure the background image covers the entire area */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  max-width: 100%; /* Ensure the box fits within the screen width */
  padding: 15px;
  margin: auto;
  border-radius: 20px;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Mobile Styles */
@media only screen and (max-width: 768px) {
  .NFTHead {
    padding: 15px;
    font-size: 14px;
    max-width: 100%; /* Ensure the container doesn't exceed screen width */
    border-radius: 20px; /* Adjust border radius for mobile */
  }

  .form-control{
    width:100%;
  }

 


  .css-1qevdtj {
    align-content: center;
  }

  .css-hj8hg5 {
    padding: 10px;
    border-radius: 15px;
    max-width: 100%;
  }


  .css-w5ho2k {
    font-size: 20px !important;
    text-align: center !important;
    color: #9fff40 !important;
    font-weight: 800 !important;
    margin: 5px !important;
}

.css-1duvf1c {
  color: #eff1a4 !important;
  font-size: 15px !important;
  text-align: center !important;
  font-weight: 400 !important;
  margin: 5px !important;
}




  .css-1xs2h3i{
    text-align: center !important;
    margin: 10px !important;

  }


  .css-jenl9d  {
    font-size: 14px;
    padding: 8px 16px;
    width: 100%; /* Ensure buttons take full width on mobile */
    margin-bottom: 10px;
    border: 3px solid #010101 !important; /* Ensure the border style is 'solid' */
    background-color: #ff66b2; /* Button background color */
    color: #fff; /* Text color */
    border-radius: 20px; /* Rounded corners for a button */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow for depth */
    cursor: pointer; /* Change cursor on hover */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .css-jenl9d:hover {
    background-color: #e64883; /* Darken the background on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhance the shadow on hover */
  }

  .btnsubmit1 {
    font-size: 14px;
    padding: 8px 16px;
    width: 100%; /* Ensure buttons take full width on mobile */
  }

  .card-header10, .card-header1 {
  
    padding: 10px;
    font-size: 14px; /* Adjust font size for mobile */
  }

  .form-control {
    font-size: 14px;
    padding: 8px;
  }

  .spacer {
    height: 15px;
  }

  .popupimage {
    padding: 15px;
    border-radius: 10px;
  }

  .content-container {
    margin-top: 10px;
  }
 

  .cardtxt {
    font-size: 14px;
    line-height: 1.4;
  }

  .MainHead {
    font-size: 18px;
    margin-bottom: 15px;
  }
  a{
    font-size: 12px !important;
  }

  /* Ensure images and media are responsive */
  img, video, iframe {
    max-width: 100%;
    height: auto;
  }

  /* Center content and remove padding from sides */
  body {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    width: 100%;
  }

  /* Ensure the content does not overflow the screen */
  .NFTHead, .card-header10, .card-header1, .css-hj8hg5 {
    width: 100%; /* Ensure the card takes the full width of the screen */
    margin: 0;
  }

  /* Adjust font sizes for better mobile experience */

}
